export default [
  {
    header: 'MI PANEL',
    resource: 'rt-plataforma',
  },
  {
    title: 'Tablero de Indicadores',
    typeIcon: 'awesome',
    route: 'rt-panel-tablero',
    icon: 'fa-solid fa-desktop',
    resource: 'rt-panel-tablero',
  },
  {
    title: 'Panel',
    icon: 'fa-solid fa-suitcase-rolling',
    typeIcon: 'awesome',
    children: [
      {
        title: 'Mi Perfil',
        icon: 'fa-solid fa-user',
        typeIcon: 'awesome',
        route: 'rt-panel-perfil',
        resource: 'rt-panel-perfil',
      },
      {
        title: 'Promociones',
        icon: 'fa-solid fa-gift',
        typeIcon: 'awesome',
        route: 'rt-panel-promociones',
        resource: 'rt-panel-promociones',
      },
      {
        title: 'Productos',
        icon: 'fa-solid fa-bag-shopping',
        typeIcon: 'awesome',
        route: 'rt-panel-productos',
        resource: 'rt-panel-productos',
      },
      {
        title: 'Chat',
        icon: 'fa-solid fa-message',
        typeIcon: 'awesome',
        route: 'rt-panel-bandeja',
        resource: 'rt-panel-bandeja',
      },
      {
        title: 'Reservas',
        icon: 'fa-solid fa-check',
        typeIcon: 'awesome',
        route: 'rt-panel-reservas',
        resource: 'rt-panel-reservas',
      },
    ],
  },
]
