<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.nombreCompleto }}
        </p>
        <span class="user-status">{{ userProfile.nombre }}</span>
      </div>

      <b-avatar
        size="40"
        :src="getUrlImage()"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="(userData.display === null || userData.display === '')"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="showModalChagePassword"
    >
      <font-awesome-icon
        icon="fal-light fa-lock"
        size="16"
        class="mr-50"
      />
      <span>Cambiar password </span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Cerrar sesión</span>
    </b-dropdown-item>

    <!-- modal-chage-password -->
    <b-modal
      id="modal-chage-password"
      title="Cambiar contraseña"
      hide-footer

      centered
    >
      <template #modal-header>
        <h5 class="modal-title">
          Cambiar su contraseña
        </h5>

      </template>
      <b-form @submit.prevent="chagePassword">
        <b-form-group
          label="Contraseña"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            required
            placeholder="Ingrese su contraseña nueva"
          />
        </b-form-group>
        <b-form-group
          label="Confirmar contraseña"
          label-for="password_confirmation"
        >
          <b-form-input
            id="password_confirmation"
            v-model="form.password_confirmation"
            type="password"
            required
            placeholder="Repita su contraseña nueva"
          />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
        >
          Cambiar contraseña
        </b-button>
      </b-form>
    </b-modal></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, BForm, BButton, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import SocketioService from '@/services/socket'
import store from '@/store'
import { showToast } from '@/helpers'
import Vue from 'vue'
import config from '@/services/config'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BModal,
    // BDropdownDivider,
    BAvatar,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userProfile: JSON.parse(localStorage.getItem('userProfile')),
      form: {
        password: '',
        password_confirmation: '',
      },
      showToast,
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('tokenPI')
      localStorage.removeItem('accessToken')// Reset ability
      this.$ability.update(initialAbility)
      SocketioService.disconnect()
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    showModalChagePassword() {
      this.$bvModal.show('modal-chage-password')
    },

    chagePassword() {
      if (this.form.password !== this.form.password_confirmation) {
        this.showToast('Advertencia', 'Las contraseñas no coinciden', 'info')
        return
      }
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea cambiar la contraseña?, se cerrará la sesión actual',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const object = {
            idUsuario: this.userData.id,
            password: this.form.password,
          }
          store.dispatch('seguridad/USUARIO_CHANGE_PASSWORD', object).then(() => {
            this.showToast('Exito', 'Contraseña cambiada correctamente', 'success')
            this.$bvModal.hide('modal-chage-password')
            /* settime  1 seg */
            setTimeout(() => {
              this.logout()
            }, 2000)
          }).catch(() => {
            this.showToast('Error', 'Error al cambiar la contraseña', 'danger')
          })
        }
      })
    },
    getUrlImage() {
      const { baseURL, contextPI } = config

      const pathMultimedia = `${baseURL}${contextPI}/api/v1/multimedia/byUrl?isthumb=true&url=${this.userData.display}`

      return pathMultimedia
    },
  },
}
</script>
